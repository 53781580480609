import * as Types from "../actionTypes/index"

const initialState = {
    mapData: "",
    tokenInfo: null,
    userInfo: "",
    providerList: null,
    providersResponse: null,
    providerDetailsData: null,
    providerDetailsDataLength: 0,
    currentProviderDetails: null,
    showNavSearchModal: false,
    showLoader: false,
    showSearchTipModal: false,
    completeProvidersList: [],
    latestApiCompleteProvidersList: [],
    zipcodesList: [],
    nearByZipcodes: "",
    isLoginurl: false,
    showCategorySearch: false,
    preferredList: [],
    preferredDetailsData: [],
    selectedDropDownDetails: null,
    cityZipCode: "",
    conditionsProceduresList: [],
    diagnosisList: [],
    diagnosisResponse: null,
    loaderCount: 0,
    specialtyList: null,
    specialtiesResponse: null,
    showCategoryPage: false,
    drugList: [],
    selectedDrug: null,
    selectedDrugDetails: null,
    radius: "",
    networkProfilesData: null,
    selectedNetworkId: null,
    searchModalCached: false,
    showPageLevelError: false,
    showCategoryPageLevelError: false
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_MAP_DATA:
            return {
                ...state,
                mapData: action.payload
            }
        case Types.GET_TOKEN_SUCCESS:
            return {
                ...state,
                tokenInfo: action.tokenInfo
            }
        case Types.GET_TOKEN_FAIL:
            return {
                ...state,
                tokenInfo: null
            }
        case Types.GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case Types.GET_USER_INFO_FAIL:
            return {
                ...state,
                userInfo: null
            }

        case Types.GET_PROVIDERLIST_SUCCESS: {
            const val = state.providerList || []
            const list = action?.providerList?.providers || []
            const prefList = action?.providerList?.preferred || []
            return {
                ...state,
                providersResponse: action.providerList,
                preferredList: [...prefList],
                providerList: [...val, ...list],
                searchModalCached: action.searchModalCached
            }
        }
        case Types.RESET_PROVIDERLIST: {
            return {
                ...state,
                providersResponse: null,
                providerList: [],
                conditionsProceduresList: [],
                diagnosisList: [],
                loaderCount: 0,
                searchModalCached: false
            }
        }
        case Types.GET_PROVIDERLIST_FAIL:
            return {
                ...state,
                providersResponse: null,
                providerList: [],
                searchModalCached: false
            }
        case Types.GET_PROVIDER_DETAILS:
        case Types.GET_PUBLIC_PROVIDER_DETAILS:
        case Types.GET_CONDITIONS_PROCEDURES_DETAILS_DATA:
        case Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA:
            return {
                ...state,
                currentProviderDetails: action.payload
            }
        case Types.GET_PROVIDER_DETAILS_SUCCESS: {
            const val = state.providerDetailsData || []
            let total = state.providerDetailsDataLength || 0
            if (val.length === 0) {
                total = parseInt(action.count)
            }
            return {
                ...state,
                providerDetailsData: [...val, ...action.providerDetailsData],
                providerDetailsDataLength: total
            }
        }
        case Types.GET_PREFERRED_DATA: {
            return {
                ...state,
                preferredDetailsData: action.preferredDetailsData || null
            }
        }
        case Types.GET_PROVIDER_ALL_DETAILS_SUCCESS: {
            const val = state.completeProvidersList || []
            return {
                ...state,
                completeProvidersList: [
                    ...val,
                    ...action.completeProvidersList
                ],
                latestApiCompleteProvidersList:
                    action.latestApiCompleteProvidersList || []
            }
        }
        case Types.GET_PROVIDER_DETAILS_FAIL:
            return {
                ...state,
                completeProvidersList: [],
                latestApiCompleteProvidersList: [],
                providerDetailsData: null,
                providerDetailsDataLength: 0,
                preferredDetailsData: []
            }

        case Types.SET_MILE_RADIUS:
            return {
                ...state,
                radius: action.payload
            }
        case Types.SET_SELECTED_DROPDOWN_DETAILS:
            return {
                ...state,
                selectedDropDownDetails: action.payload
            }
        case Types.GET_ZIPCODES_SUCCESS: {
            const zipcodes = action.zipcodes
            return {
                ...state,
                ...zipcodes
            }
        }
        case Types.GET_ZIPCODES_FAIL: {
            return {
                ...state,
                zipcodesList: [],
                nearByZipcodes: ""
            }
        }
        case Types.GET_REFRESH_TOKEN:
            return {
                ...state,
                tokenInfo: action.tokenInfo
            }
        case Types.GET_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                tokenInfo: action.tokenInfo
            }
        case Types.GET_REFRESH_TOKEN_FAIL:
            return {
                ...state,
                tokenInfo: null
            }
        case Types.NAV_SEARCH_MODAL_STATUS:
            return {
                ...state,
                showNavSearchModal: action.payload
            }
        case Types.IS_SEARCHTIP_MODAL_SHOW:
            return {
                ...state,
                showSearchTipModal: action.payload
            }
        case Types.GLOBAL_ERROR_MESSAGE:
            return {
                ...state,
                globalErrorMessage: action.payload
            }
        case Types.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case Types.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case Types.INCREASE_LOADER_COUNT: {
            const loaderCount = state.loaderCount + 1
            return {
                ...state,
                loaderCount,
                showLoader: true
            }
        }
        case Types.DECREASE_LOADER_COUNT: {
            const count = state.loaderCount - 1
            return {
                ...state,
                loaderCount: count <= 0 ? 0 : count,
                showLoader: count <= 0 ? false : true
            }
        }
        case Types.LOGIN_TYPE:
            return {
                ...state,
                loginType: action.payload
            }
        case Types.IS_LOGIN_URI:
            return {
                ...state,
                isLoginurl: action.payload
            }
        case Types.SET_CATEGORY_VIEW_SHOW:
            return {
                ...state,
                showCategorySearch: action.payload
            }
        case Types.GET_CITY_ZIPCODE_SUCCESS: {
            return {
                ...state,
                cityZipCode: action.zipcode
            }
        }
        case Types.GET_CITY_ZIPCODE_FAIL: {
            return {
                ...state,
                cityZipCode: ""
            }
        }
        case Types.GET_CONDITIONS_PROCEDURES_DATA_SUCCESS: {
            const list = action.data || []
            return {
                ...state,
                conditionsProceduresList: list
            }
        }
        case Types.GET_DIAGNOSIS_DATA_SUCCESS: {
            const val = state.diagnosisList || []
            const list = action.diagnosisList?.diagnosis || []
            return {
                ...state,
                diagnosisResponse: action.diagnosisList,
                diagnosisList: [...val, ...list]
            }
        }

        case Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DATA:
        case Types.GET_CONDITIONS_PROCEDURES_DATA:
        case Types.GET_CONDITIONS_PROCEDURES_DATA_FAIL: {
            return {
                ...state,
                conditionsProceduresList: [],
                specialtiesResponse: null,
                specialtyList: [],
                diagnosisList: []
            }
        }
        case Types.GET_SPECIALTYLIST_SUCCESS: {
            const val = state.specialtyList || []
            const list = action?.specialties || []
            return {
                ...state,
                specialtiesResponse: action.specialties,
                specialtyList: [...val, ...list]
            }
        }
        case Types.SHOW_CATEGORY_PAGE: {
            return {
                ...state,
                showCategoryPage: action.payload
            }
        }
        case Types.GET_DRUG_DATA_SUCCESS: {
            const list = action.data || []
            return {
                ...state,
                drugList: [...list]
            }
        }
        case Types.GET_DRUG_DATA_FAIL: {
            return {
                ...state,
                drugList: [],
                selectedDrug: null
            }
        }
        case Types.GET_DRUG_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                selectedDrugDetails: action.data
            }
        }
        case Types.GET_DRUG_DETAILS_DATA_FAIL: {
            return {
                ...state,
                selectedDrugDetails: null
            }
        }
        case Types.GET_NETWORK_PROFILES:
            return {
                ...state,
                networkProfilesData: null
            }
        case Types.GET_NETWORK_PROFILES_SUCCESS:
            return {
                ...state,
                networkProfilesData: action.data
            }
        case Types.GET_NETWORK_PROFILES_FAIL:
            return {
                ...state,
                networkProfilesData: null
            }
        case Types.SET_SELECTED_NETWORK_ID:
            return {
                ...state,
                selectedNetworkId: action.value
            }
        case Types.SHOW_PAGE_LEVEL_ERROR: {
            return {
                ...state,
                showPageLevelError: action.payload
            }
        }
        case Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR: {
            return {
                ...state,
                showCategoryPageLevelError: action.payload
            }
        }
        default:
            return state
    }
}
