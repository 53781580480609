import { put, select, takeLatest } from "redux-saga/effects"

import {
    getPublicProviderListService,
    fetchPublicProvidersInParallel
} from "../service/getPublicProviderList.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getPublicProviderList(action) {
    const { payload } = action
    yield put({ type: Types.INCREASE_LOADER_COUNT })
    try {
        const storeData = yield select(state)
        const { userInfo, nearByZipcodes, selectedNetworkId } = storeData || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        // let results = yield getPublicProviderListService({
        //     payload,
        //     detailType: "basic",
        //     sponsorId: sponsorId,
        //     employerId: userInfo?.employerId || "",
        //     planId: userInfo?.planId || "",
        //     nearByZipcodes,
        //     networkId: selectedNetworkId
        // })

        // RDS Proxy Code
        let results
        if (payload?.count === undefined) {
            results = yield getPublicProviderListService({
                payload,
                detailType: "basic",
                sponsorId: sponsorId,
                employerId: userInfo?.employerId || "",
                planId: userInfo?.planId || "",
                nearByZipcodes,
                networkId: selectedNetworkId
            })
        } else {
            results = yield fetchPublicProvidersInParallel({
                payload,
                detailType: "basic",
                sponsorId: sponsorId,
                employerId: userInfo?.employerId || "",
                planId: userInfo?.planId || "",
                nearByZipcodes,
                networkId: selectedNetworkId
            })
        }
        const { data, apiStatus, cached } = results
        if (apiStatus === "success") {
            yield put({
                type: Types.GET_PROVIDERLIST_SUCCESS,
                providerList: data,
                searchModalCached: cached
            })
            if (
                Number(data.count) < Number(data?.searchparams?.limit) &&
                (data?.providers?.length === 0 || !data?.providers?.length)
            ) {
                yield put({
                    type: Types.GLOBAL_ERROR_MESSAGE,
                    payload:
                        "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                })
                payload.navigate("/error")
            }
            yield put({
                type: "SET_CATEGORY_VIEW_SHOW",
                payload: true
            })
        } else {
            yield put({ type: Types.GET_PROVIDERLIST_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            payload.navigate("/error")
        }
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    } catch (error) {
        yield put({ type: Types.GET_PROVIDERLIST_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
        payload.navigate("/error")
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    }
}

export function* getPublicProviderListSaga() {
    yield takeLatest(Types.GET_PUBLIC_PROVIDERLIST, getPublicProviderList)
}
