import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"

import { employerPlansData } from "../../utils/employerPlansData"
import { ThemeContext } from "../../Theme"

import "./styles.css"

const Partners = [
    { "coupe-anthem": "anthem" },
    { coupe: "coupe-2024" },
    // { "coupe-mn": "mn" },
    { "coupe-bcbsmn": "coupe-2025" },
    { "coupe-bcbsal": "bcbsal" },
    { "coupe-hcsc": "hcsc" }
    // { "coupe-na": "na" }
]

const SPHPartners = [
    { simplepay: "simplepay-2024" },
    { "simplepay-2025": "simplepay-2025" }
]

const PublicLanding = () => {
    const [selectedType, setSelectedType] = useState("")
    const [partnersList, setPartnersList] = useState([])
    const [employerIdList, setEmployerIdList] = useState([])
    const [employerId, setEmployerid] = useState("")
    const [partnersId, setPartnersId] = useState("")
    const [planIdList, setPlanIdList] = useState([])
    const [planId, setPlanId] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { theme } = useContext(ThemeContext)
    useEffect(() => {
        sessionStorage.removeItem("empType")
        sessionStorage.removeItem("url")
        if (location.pathname === "/internal" && theme) {
            window.location.reload()
        }
    }, [location])
    const handleThemeSelect = () => {
        if (selectedType) {
            const userInfo = {
                "custom:sponsorid": selectedType,
                employerId,
                planId,
                partnersId
            }
            dispatch({ type: "GET_USER_INFO_SUCCESS", userInfo })
            dispatch({ type: "LOGIN_TYPE", payload: "external user login" })
            dispatch({ type: "IS_LOGIN_URI", payload: false })
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
            sessionStorage.setItem("loginType", "external user login")
            navigate("/")
        }
    }
    const resetValue = () => {
        setEmployerIdList([])
        setEmployerid("")
        setPlanIdList([])
        setPlanId("")
        setSelectedType("")
    }
    const handleThemeClick = (id) => {
        if (id === "6106") {
            setPartnersList(SPHPartners)
        } else {
            setPartnersList(Partners)
        }
        setEmployerIdList([])
        setEmployerid("")
        setPlanIdList([])
        setPlanId("")
        setSelectedType(id)
        const { coupe, simplepay } = employerPlansData || {}
        if (coupe?.sponsorid === id) {
            setEmployerIdList(coupe?.employers)
        } else if (simplepay?.sponsorid === id) {
            setEmployerIdList(simplepay?.employers)
        }
    }

    return (
        <div className="PublicLanding-wrapper">
            <div>
                <div className="PublicLanding-container">
                    <h2>
                        Want to see how much smoother your health ride can be?
                    </h2>
                    <p>
                        Select a product below to take a glimpse at our Provider
                        Search Portal
                    </p>
                    <div className="d-flex btn-wrapper pb-4 row">
                        <div className="col-xs-6 col-sm-4 col-lg-2 mt-3 pr-0">
                            <Button
                                variant="outlined"
                                className={
                                    selectedType === "7140" ? "activeCoupe" : ""
                                }
                                onClick={() => handleThemeClick("7140")}
                            >
                                Coupe
                            </Button>
                        </div>
                        <div className="col-xs-6 col-sm-4 col-lg-2 mt-3 pr-0">
                            <Button
                                variant="outlined"
                                className={
                                    selectedType === "6106"
                                        ? "activeSimple"
                                        : ""
                                }
                                onClick={() => handleThemeClick("6106")}
                            >
                                Simple Pay
                            </Button>
                        </div>
                    </div>
                    <div className="drop-down-wrapper mt-2">
                        {
                            // selectedType === "7140" && (
                            <div className="align-items-baseline d-flex flex-column flex-sm-row mt-3 pr-0">
                                <p className="pr-3">Select Partners :</p>
                                <FormControl
                                    size="small"
                                    className="col-xs-6 col-sm-4 col-lg-2 pl-0 pr-0"
                                >
                                    <InputLabel id="Partners-select-label">
                                        Partners Name
                                    </InputLabel>
                                    <Select
                                        labelId="Partners-select-label"
                                        id="Partners-select"
                                        value={partnersId}
                                        label="Partners Name"
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setPartnersId(e.target.value)

                                            const res = employerPlansData || {}
                                            setEmployerIdList(
                                                res[e.target.value]?.employers
                                            )
                                            setPlanIdList([])
                                        }}
                                    >
                                        {partnersList?.length ? (
                                            partnersList.map((item, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={Object.keys(item)[0]}
                                                >
                                                    {item[Object.keys(item)[0]]}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem>
                                                Please choose the product above
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            // )
                        }

                        <div className="align-items-baseline d-flex flex-column flex-sm-row mt-3 pr-0">
                            <p className="pr-3">Select Employer :</p>
                            <FormControl
                                size="small"
                                className="col-xs-6 col-sm-4 col-lg-2 pl-0 pr-0"
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Employer Name
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employerId}
                                    label="Employer Name"
                                    onChange={(e) => {
                                        setEmployerid(e.target.value)
                                        setPlanIdList([])
                                        employerIdList.forEach((ele) => {
                                            if (
                                                ele?.employerid ===
                                                e.target.value
                                            ) {
                                                setPlanIdList(ele?.plans)
                                            }
                                        })
                                    }}
                                >
                                    {employerIdList?.length ? (
                                        employerIdList.map((item, i) => (
                                            <MenuItem
                                                key={i}
                                                value={item?.employerid}
                                            >
                                                {item?.employername}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem>
                                            Please choose the product above
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="align-items-baseline d-flex flex-column flex-sm-row mt-3 pr-0">
                            <p className="pr-3">Select Plan Type :</p>
                            <FormControl
                                size="small"
                                className="col-xs-6 col-sm-4 col-lg-2 pl-0 pr-0"
                            >
                                <InputLabel id="simple-sub-label">
                                    Plan Type
                                </InputLabel>
                                <Select
                                    labelId="simple-sub-label"
                                    id="simple-sub"
                                    value={planId}
                                    label="Employer Sub Name"
                                    onChange={(e) => {
                                        setPlanId(e.target.value)
                                    }}
                                >
                                    {planIdList?.length ? (
                                        planIdList.map((item, i) => (
                                            <MenuItem
                                                key={i}
                                                value={item?.planid}
                                            >
                                                {item?.planname}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem>
                                            Please choose the Employer above
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="continue-btn-wrapper">
                    <Button onClick={() => resetValue()} variant="outlined">
                        Reset
                    </Button>
                    <Button
                        className="ml-3"
                        disabled={!selectedType || !employerId || !planId}
                        onClick={() => handleThemeSelect()}
                        variant="contained"
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PublicLanding
