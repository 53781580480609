import { employerPlansData } from "./employerPlansData"

export const getDirectLandingUrlParams = (params) => {
    const product = params.get("product")
    const employer = params.get("employerId")
    const plan = params.get("planId") || ""
    let planId = ""
    let sponsorId = ""
    let employerId = ""
    if (product && employer) {
        const theme = product.toLowerCase()
        const str = theme.replace(/ +/g, "")
        sponsorId = employerPlansData[str]?.sponsorid || ""
        employerPlansData[str]?.employers?.forEach((element) => {
            if (element.employerid === employer) {
                employerId = element.employerid
                element?.plans?.forEach((item) => {
                    if (item.planid === plan) {
                        planId = item.planid
                    }
                })
            }
        })
    }

    return { sponsorId, employerId, planId, product }
}

// common logic to get tier color
export const getTierBgColor = (default_tier) => {
    if (default_tier === 1) {
        return "#2e7d32"
    } else if (default_tier === 2) {
        return "#f9a925"
    } else if (default_tier === 3) {
        return "#d94214"
    } else if (default_tier === 4) {
        return "#7675758c"
    }
}

export const getHeaderStatus = (url) => {
    return url === "/internal"
}

// used to hide nav bar
export const currentNavigation = (showNav) => {
    return (
        showNav !== "/" &&
        showNav !== "/error" &&
        showNav !== "/employers/" &&
        // showNav !== "/generateProviderReport" &&
        showNav !== "/internal" &&
        showNav !== "/DrugResults"
    )
}

export const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "-webkit-transform": "translate(-50%, -50%)",
    width: "400px",
    maxHeight: "400px",
    overflow: "auto",
    bgcolor: "background.paper",
    color: "#000000de",
    boxShadow: 24,
    p: 10
}

export const product = (userInfo) => {
    if (userInfo) {
        let userName = userInfo["custom:sponsorid"]
        if (userName === "7140") {
            return 1 //"coupe-health"
        } else if (userName === "6106") {
            return 2 //"simplePay-health"
        }
    }
    return 0
}

export const isFacilityWithCopay = (item) => {
    let facilities = ["HOSPITAL", "FACILITY", "GROUP/CLINIC"]
    let benefitCodesExcluded = [
        "ADVANCED IMAGING",
        "ADVANCED LAB",
        "ROUTINE IMAGING",
        "URGENT CARE"
    ]
    return (
        item &&
        item.provider_type &&
        facilities.includes(item.provider_type.toUpperCase()) &&
        item.benefitcode_text &&
        !benefitCodesExcluded.includes(item.benefitcode_text.toUpperCase()) &&
        item.override_tier === null &&
        item.override_rate === null
    )
}
export const checkIsCSurlDisplay = (params) => {
    let { employerId, product } = getDirectLandingUrlParams(params)

    const obj = sessionStorage.getItem("userInfo")
    const userInfo = JSON.parse(obj)
    if (userInfo) {
        employerId = userInfo.employerId
        if (!product) {
            product = userInfo.partnersId
        }
    }
    const productinkStatus =
        employerPlansData?.[product]?.flags?.customerserviceurls
    const { empLinkStatus, csLink, csText } = getEmpLinkShowStatus(
        product,
        employerId
    )
    return { productinkStatus, empLinkStatus, csLink, csText }
}

export const getEmpLinkShowStatus = (product, employerId) => {
    let empLinkStatus = false
    let csLink = ""
    let csText = ""
    employerPlansData[product]?.employers?.forEach((element) => {
        if (element.employerid === employerId) {
            empLinkStatus = element.flags?.customerserviceurls
            csLink = element.csLink
            csText = element.csText
        }
    })
    return { empLinkStatus, csLink, csText }
}
