import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import { DataGrid } from "@mui/x-data-grid"

import "./GenerateProvidersReport.css"

const GenerateProvidersReport = () => {
    const dispatch = useDispatch()
    const store = useSelector((state) => state)
    const {
        currentProviderDetails,
        completeProvidersList = [],
        latestApiCompleteProvidersList = []
    } = store
    const [providerDataList, setProviderDataList] = useState([])
    const containerRef = useRef(null)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            field: "provider_name",
            headerName: "Name",
            width: 300
        },
        {
            field: "speciality_category",
            headerName: "Speciality",
            width: 300
        },
        {
            field: "default_tier",
            headerName: "Tier",
            width: 100,
            valueGetter: (params) =>
                `${
                    params?.row?.override_tier ||
                    params?.row?.default_tier ||
                    ""
                }`
        },
        {
            field: "default_rate_one",
            headerName: "Co-Pay",
            width: 150,
            valueGetter: (params) =>
                `${
                    params?.row?.override_rate !== null ||
                    params?.row.default_rate !== null
                        ? "$"
                        : ""
                } ${params?.row.override_rate || params?.row.default_rate}`
        },
        { field: "phone_number", headerName: "Phone Number", width: 200 },
        {
            field: "address_one",
            headerName: "Address",
            width: 200,
            valueGetter: (params) =>
                `${params.row?.addr_ln_1 || ""} ${params.row?.addr_ln_2 || ""}`
        },
        { field: "city", headerName: "City", width: 150 },
        { field: "state_cd", headerName: "State", width: 100 },
        { field: "zipcode", headerName: "Zip Code", width: 125 }
    ]

    useEffect(() => {
        const dataRes = JSON.parse(JSON.stringify(completeProvidersList)) || []
        const id = currentProviderDetails?.provider_id
        dataRes?.sort((x, y) => {
            return x?.provider_id === id ? -1 : y?.provider_id === id ? 1 : 0
        })
        setLoading(false)
        setProviderDataList(dataRes)
    }, [currentProviderDetails, completeProvidersList])

    const onSelectionModelChange = (param1, param2) => {
        console.log(param1, param2)
    }
    useEffect(() => {
        let grid = containerRef.current
        if (grid) {
            grid.addEventListener("scroll", handleScroll)
        }
        return () => {
            if (grid) {
                grid?.removeEventListener("scroll", handleScroll)
            }
        }
    }, [loading, containerRef])

    const handleLoadMore = () => {
        if (latestApiCompleteProvidersList?.length) {
            let offsetVal = 0
            if (
                currentProviderDetails?.offset ||
                currentProviderDetails?.offset === 0
            ) {
                offsetVal = 30 + currentProviderDetails.offset
            }
            const res = {
                ...currentProviderDetails,
                diagnosisCode: "",
                offset: offsetVal,
                limit: 30,
                getAllResults: true,
                navigate: null,
                type: "loadMore"
            }
            let actionType = "GET_PUBLIC_PROVIDER_DETAILS"
            if (currentProviderDetails?.title === "procedureCode") {
                res["subType"] = "procedureCode"
                res["procedureCodes"] = currentProviderDetails.procedureCode
                actionType = "GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA"
            } else if (currentProviderDetails.title === "specialties") {
                res["subType"] = "specialties"
                actionType = "GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA"
            }
            dispatch({
                type: actionType,
                payload: res
            })
        } else {
            alert("you have reached maximum limit")
            setLoading(false)
        }
    }
    // const printPage = () => {
    // window.print()
    // }

    const handleScroll = (e) => {
        const targ = e ? e.target : containerRef.current
        if (
            targ.clientHeight + targ.scrollTop >= targ.scrollHeight &&
            !loading
        ) {
            setLoading(true)
            handleLoadMore()
        }
    }

    return (
        <>
            <div className="GenerateProviderReport-landing" ref={containerRef}>
                <div className="GenerateProviderWrapper">
                    {/* <button
                            className="print-btn btn btn-default mb-1"
                            onClick={printPage}
                        >
                            Print Report
                        </button> */}
                    <div className="inner-cont" onScroll={handleScroll}>
                        {providerDataList?.length > 0 && (
                            <DataGrid
                                rows={providerDataList}
                                columns={columns}
                                getRowId={(row) => row.provider_id}
                                checkboxSelection
                                onRowSelectionModelChange={
                                    onSelectionModelChange
                                }
                                loading={loading}
                                autoHeight
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default GenerateProvidersReport
