import React, { useState, useContext, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PropTypes } from "prop-types"

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { MenuItem, Select } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Button } from "@mui/material"

import Search from "../search/search"
import RangeSlider from "./rangeSlider"
import { ThemeContext } from "../../Theme"
import * as Types from "../../redux/actionTypes/index"

import "./styles.css"
import { checkIsCSurlDisplay } from "../../utils/common"

const LocationSearch = (props) => {
    const {
        selectedCompleteAddress,
        partnersId,
        networkProfilesData,
        loginType,
        sponsorid
    } = props
    const params = new URLSearchParams(location.search)
    const dispatch = useDispatch()
    const { theme } = useContext(ThemeContext)
    const [milesVal, setMilesVal] = useState(5)
    const [networkProfilesDataList, setNetworkProfilesDataList] = useState({})
    const [networkvalue, setNetworkvalue] = useState("")
    const [hideSupportUrl, setHideSupportUrl] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        const val = {
            ...networkProfilesData,
            BLUECARD: "National BlueCard Network"
        }
        setNetworkProfilesDataList(val)
        setNetworkvalue("BLUECARD")
        dispatch({ type: "SET_SELECTED_NETWORK_ID", value: "BLUECARD" })
    }, [networkProfilesData])

    useEffect(() => {
        const { productinkStatus, empLinkStatus, csLink, csText } =
            checkIsCSurlDisplay(params)
        setHideSupportUrl({
            status: productinkStatus || empLinkStatus,
            csLink,
            csText
        })
    }, [])

    useEffect(() => {
        if (loginType === "external user login" && sponsorid === "7140") {
            dispatch({ type: "GET_NETWORK_PROFILES", partnersId: partnersId })
        }
    }, [partnersId])

    // handling click event on address select
    const updateLocationAddress = (data) => {
        props.updateSetSelectedCompleteAddress(data)
        // dispatch({ type: Types.SET_SELECTED_ADDRESS, payload: data })
        if (data.zipCode) {
            dispatch({ type: Types.GET_CITY_ZIPCODE_FAIL })
        } else {
            dispatch({ type: Types.GET_CITY_ZIPCODE, payload: data })
        }
    }

    const handleSelectChange = (e) => {
        dispatch({ type: "SET_SELECTED_NETWORK_ID", value: e.target.value })
        setNetworkvalue(e.target.value)
    }
    const handleSearchClick = () => {
        let radiusValue = milesVal
        if (document.querySelector("#rangeSlider input")?.value) {
            radiusValue = document.querySelector("#rangeSlider input").value
        }
        const obj = {
            ...selectedCompleteAddress,
            radius: radiusValue,
            providerListPayload: {
                ...selectedCompleteAddress,
                navigate
            }
        }
        dispatch({
            type: Types.SET_SELECTED_DROPDOWN_DETAILS,
            payload: null
        })
        dispatch({ type: Types.SET_MILE_RADIUS, payload: radiusValue })
        dispatch({ type: Types.GET_ZIPCODES, payload: obj })
        dispatch({ type: Types.RESET_PROVIDERLIST })
        props.openCategorySearchBar(true)
    }

    const updateVal = (e) => {
        if (!e.target.value) {
            props.updateSetSelectedCompleteAddress("")
        }
    }
    const handleRangeChanges = (val) => {
        setMilesVal(val)
        // dispatch({ type: Types.RESET_PROVIDERLIST })
    }
    const isNetworkEnabled = () => {
        return loginType === "external user login" && sponsorid === "7140"
    }

    return (
        <>
            <div className="search-bar-class text-left">
                {isNetworkEnabled() && (
                    <div className="text-left search-outline-container mb-4 w-100">
                        <label
                            className="font-weight-bold provider-lable-flds"
                            htmlFor="locationFlds"
                        >
                            Select Your Network
                        </label>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            variant="standard"
                            value={networkvalue}
                            label="Select Your Network"
                            className="w-100"
                            onChange={handleSelectChange}
                        >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}
                            {networkProfilesDataList &&
                                Object.keys(networkProfilesDataList).length &&
                                Object.keys(networkProfilesDataList).map(
                                    (item, i) => (
                                        <MenuItem key={i} value={item}>
                                            {networkProfilesDataList[item]}
                                        </MenuItem>
                                    )
                                )}
                        </Select>
                    </div>
                )}

                <div className="mb-4">
                    <Search
                        updateAddress={updateLocationAddress}
                        updateVal={updateVal}
                    />
                </div>
                <label className=" mb-4 provider-lable-flds font-weight-bold">
                    Set Your Range:
                </label>
                <RangeSlider
                    value={milesVal}
                    handleRangeChanges={handleRangeChanges}
                />
            </div>

            <div className="criteria-button mt-4">
                <Button
                    disabled={!selectedCompleteAddress}
                    variant="outlined"
                    color="primary"
                    aria-label="search"
                    onClick={handleSearchClick}
                    startIcon={<SearchIcon />}
                >
                    Search
                </Button>
            </div>
            {props.type !== "navSearch" && (
                <div className="shared-workflow-container mt-4 justify-content-center help-icon d-flex">
                    {!hideSupportUrl.status && (
                        <Button
                            variant="text"
                            aria-label="Need help?"
                            className="text-left disclouser-btn-links"
                            onClick={() => {
                                let url = hideSupportUrl.csLink
                                if (!url) {
                                    url =
                                        theme === "coupe-health"
                                            ? "https://employers.coupehealth.com/CoupeHealthContact.html"
                                            : "https://employers.simplepayhealth.com/SimplePayHealthContact.html"
                                }
                                window.open(url)
                            }}
                            startIcon={<HelpOutlineOutlinedIcon />}
                        >
                            {hideSupportUrl.csText
                                ? hideSupportUrl.csText
                                : "Need Help or Can't Find Your Provider? Please contact the Health Valet"}
                        </Button>
                    )}
                    <Button
                        variant="text"
                        aria-label="Prerequisites and Disclosure Notice"
                        className="text-left disclouser-btn-links"
                        startIcon={<InfoOutlinedIcon />}
                        onClick={() => {
                            const url =
                                theme === "coupe-health"
                                    ? "https://www.coupehealth.com/wp-content/uploads/2024/02/98738ks7.pdf"
                                    : "https://www.simplepayhealth.com/wp-content/uploads/2024/02/0128657.pdf"
                            window.open(url)
                        }}
                    >
                        Prerequisites and Disclosure Notice
                    </Button>
                </div>
            )}
        </>
    )
}

LocationSearch.propTypes = {
    openCategorySearchBar: PropTypes.func.isRequired,
    type: PropTypes.any,
    updateSetSelectedCompleteAddress: PropTypes.func.isRequired,
    selectedCompleteAddress: PropTypes.any,
    partnersId: PropTypes.any,
    networkProfilesData: PropTypes.any,
    loginType: PropTypes.any,
    sponsorid: PropTypes.any
}
export default LocationSearch
