import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
import protobuf from "./protos/basicPublicProvider.proto"
import { Buffer } from "buffer"
import { employerPlansData } from "../../utils/employerPlansData"

const getPublicProvidersResponse = async (encodeResponse) => {
    return await load(protobuf).then(function (root) {
        const BasicProviderResponse = root.lookupType(
            "providerSearch.BasicPublicProviderResponse"
        )
        const decodedResponse = BasicProviderResponse.decode(
            Buffer.from(encodeResponse.data, "base64")
        )
        return {
            data: decodedResponse,
            apiStatus: "success",
            cached: encodeResponse.cached
        }
    })
}

export const getPublicProviderListService = async ({
    payload,
    detailType,
    sponsorId,
    providerId,
    planId,
    employerId,
    nearByZipcodes,
    networkId
}) => {
    const {
        city,
        state,
        zipCode,
        providerType,
        offset = 0,
        getAllResults,
        radius
    } = payload
    let url = ""

    const zCode = nearByZipcodes || zipCode
    let queryUrl = ""
    if (sponsorId === "7140") {
        queryUrl = `&networkId=${networkId}`
    }

    let productId = new URLSearchParams(window.location.search).get("product")
    if (!productId) {
        const obj = sessionStorage.getItem("userInfo")
        const userInfo = JSON.parse(obj)
        if (userInfo) {
            productId = userInfo.partnersId
        }
    }
    console.log("Product ID:", productId)

    const selectedpublicProviderEndpoint =
        (productId &&
            employerPlansData[productId].endpointsList.publicproviders) ||
        "/coupe/v3/publicproviders"

    if (getAllResults) {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&zipcode=${zCode}&city=${city}&state=${state}&limit=30&offset=${offset}&providerType=${providerType}&providersList=${providerId}${queryUrl}`
    } else if (detailType === "basic") {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}${queryUrl}`

        let basicSearchRes = await axiosInstance({
            method: "get",
            url
        })
        console.log("Basic Search Response:", basicSearchRes)
        return await getPublicProvidersResponse(basicSearchRes)
    } else {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&zipcode=${zCode}&city=${city}&state=${state}&limit=10&offset=${offset}&providerType=${providerType}&providersList=${providerId}${queryUrl}`
    }
    return axiosInstance({
        method: "get",
        url
    })
}

export const fetchPublicProvidersInParallel = async ({
    payload,
    detailType,
    sponsorId,
    planId,
    employerId,
    nearByZipcodes,
    networkId
}) => {
    const zCode = nearByZipcodes || payload?.zipCode
    payload.zCode = zCode
    payload.networkId = networkId

    let providerConcurrentresults = await Promise.all(
        getProviderRequests(payload, sponsorId, planId, detailType, employerId)
    )
    let providers = []
    let specialties = []
    let searchparams = []
    let insurancePlanType = []
    let apiStatus = ""
    for (let i = 0; i < providerConcurrentresults.length; i++) {
        let res = providerConcurrentresults[i]
        if (
            !res?.data?.statusCode === undefined &&
            res?.data?.statusCode !== 200
        ) {
            apiStatus = "fail"
        }
        if (res?.data?.statusCode === undefined) {
            providers = providers.concat(res?.data?.providers)
            specialties = specialties.concat(res?.data?.specialties)
            searchparams.push(res?.data?.searchparams)
            insurancePlanType.push(res?.data?.insurance_plantype)
        }
    }
    return {
        apiStatus: apiStatus !== "fail" ? "success" : "fail",
        data: {
            providers: providers,
            specialties: specialties,
            searchparams: searchparams,
            insurance_plantype: [...new Set(insurancePlanType)],
            count: payload?.count
        }
    }
}
const getProviderRequests = (
    payload,
    sponsorId,
    planId,
    detailType,
    employerId
) => {
    const providerRequests = []
    let { count, city, state, zCode, radius, networkId } = payload
    const totalVal = count

    // const productId = new URLSearchParams(window.location.search).get("product")
    let productId = new URLSearchParams(window.location.search).get("product")
    if (!productId) {
        const obj = sessionStorage.getItem("userInfo")
        const userInfo = JSON.parse(obj)
        if (userInfo) {
            productId = userInfo.partnersId
        }
    }
    console.log("Product ID:", productId)

    const selectedpublicProviderEndpoint =
        (productId &&
            employerPlansData[productId].endpointsList.publicproviders) ||
        "/coupe/v3/publicproviders"
    // let { count, city, state, zCode } = payload
    let offset = 50000
    while (count > 0 && offset <= totalVal) {
        // console.log(count, "count")
        let url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}&networkId=${networkId}`

        // let url = `${process.env.REACT_APP_API_URL}/v2/coupe/publicproviders?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}`
        providerRequests.push(
            axiosInstance({
                method: "get",
                url: url
            }).then((response) => {
                return getPublicProvidersResponse(response)
            })
        )
        offset += 50000
        count -= 50000
    }
    return providerRequests
}
